import React from "react";
import ProjectCard from "./ProjectCard";

const Project = () => {
  return (
    <div className="my-5 pt-4 text-center container " id="project">
      <h1 className="mt-5 p-5">Projects</h1>
      <div className="row">
        <ProjectCard
          title="Appointment Scheduler"
          description="This is a patient doctor appointment booking Web Application. Integrated Google Calendar and Google Meet for virtual appointment bookings. Also implement a stripe payment gateway."
          website="https://healthcarebooking.netlify.app/"
          github="https://github.com/Project-Based-Learning-IT/healthcare-appointment-scheduling-app"
        />
        <ProjectCard
          title="BloggingSphere"
          description="A dynamic and user-friendly platform designed to empower content creators and readers by offering seamless user authentication, efficient post management, and a responsive design. "
          website="https://blogging-mayank.vercel.app/"
          github="https://github.com/Mayank8085/BloggingFrontend"
          stack = {["ReactNative", "Node", "Express", "MongoDB"]}
        />
        <ProjectCard
          title="Expense Tracker"
          description="App with features for managing, categorizing, and visualizing expenses, ensuring a responsive and intuitive user experience"
          github="https://github.com/Mayank8085/ExpenseTracker"
          website=""
        />
        <ProjectCard />
        <ProjectCard
          title="GitHub Clone"
          description="A web application that displays GitHub profile and Repository info
                according to the username search."
          stack={["React", "Firebase", "GitHub API", "Bootstrap"]}
          website="https://mayankgithub.netlify.app/"
          github="https://github.com/Mayank8085/firbase-github"
        />
        <ProjectCard
          title="JS World"
          description="Collection of mini js projects using dom-manipulation and basic
                javascript. Also basic of CSS & HTML."
          stack={["HTML", "CSS", "JavaScript"]}
          website="https://mayank8085.github.io/ReactProjects/"
          github="https://github.com/Mayank8085/ReactProjects"
        />
      </div>
    </div>
  );
};

export default Project;
